import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'
import "../styles/main.css"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Helmet } from "react-helmet"
 
// markup
const IndexPage = () => {
  return (
   
    <main>
      <Helmet>  
        <title>Martin Zerr – Design</title>
        <meta name="description" content="Ich bin Martin Zerr, bei mir gibt es Fotografie und Design aus Potsdam!" data-react-helmet="true"></meta>
        <meta name="title" content="Martin Zerr – Design – Fotografie" data-react-helmet="true"></meta>
      </Helmet> 
      
            <section className="start_top">
         
          <div class="infos">
            <h1>Martin Zerr</h1>

            <h2 className="alter">23 Jahre alt</h2>
          </div>

          
            <h2 class="info_h2"><span>ausgebildeter <br />
            Mediengestalter</span><br />
            <span>Designstudierender <br />
              an der FH Potsdam</span>
            </h2>
          

        
        <StaticImage
          className="martinimg"
          src="../images/martin_zerr.jpg"
          alt="martin zerr"
          placeholder="tracedSVG"
          quality={100}
        />
 

      </section>

      <h3>Fotoprojekte</h3>
      <section className="projects">
     
        <a href="/fotostudio">
          <div className="p_studio">
                <StaticImage src="../images/studio_cover.jpg" alt="" placeholder="blurred" quality={100}/>
           <p>Im Fotostudio</p> 
          </div>
        </a>

        <a href="/rad">
          <div className="p_rad">
                <StaticImage src="../images/cover_rad.jpg" alt="" placeholder="blurred" quality={100}/>
            <p>Fahrradfotografie</p>
          </div>
        </a>

        <a href="/hotels">
         <div className="p_hotels">
              <StaticImage src="../images/hotels_cover.jpg" alt="" placeholder="blurred" quality={100}/>
            <p>Fünf Sterne – Luxushotels</p>
          </div>
        </a>

        <a href="/serien">
         <div className="p_serien">
              <StaticImage src="../images/serien_vorschau.jpg" alt="" placeholder="blurred" quality={100}/>
            <p>Fünfer Serien</p>
          </div>
        </a>

      </section>




      <h3>Freie Fotografien</h3>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">

        <Zoom><StaticImage src="../images/IMG_7002_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7061_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7049_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7110_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7092_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7098_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7046_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_7095_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_703_b.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/regent_strasse.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/_MG_7166.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/_MG_7150.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/_MG_7167.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/dark_heide.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/heide_back.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/high5.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_2472.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_2538.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_3815-2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_3816-2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        {/*<Zoom><StaticImage src="../images/IMG_4277.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>*/}
        <Zoom><StaticImage src="../images/IMG_4384.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_4900.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        {/*<Zoom><StaticImage src="../images/IMG_6082.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6120.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6129.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6161.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>*/}
        <Zoom><StaticImage src="../images/IMG_6369.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6538.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6622.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        {/*<Zoom><StaticImage src="../images/IMG_6889.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>*/}
        <Zoom><StaticImage src="../images/IMG_6719.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/IMG_6721.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        {/*<Zoom><StaticImage src="../images/IMG_6905.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>*/}
        <Zoom><StaticImage src="../images/IMG_6930.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>

      </Masonry>


      <section className="blender">

        <div>
          <h3>
            Renderings mit Blender
          </h3>

        <Masonry
          breakpointCols={3}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          <Zoom><StaticImage src="../images/grau_hoch_2.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
          <Zoom><StaticImage src="../images/top_view.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
          <Zoom><StaticImage src="../images/blau_hoch.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
          <Zoom><StaticImage src="../images/render_6k_2x_with_screen.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>            
          <Zoom><StaticImage src="../images/sqare.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
          <Zoom><StaticImage src="../images/stack1.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
          <Zoom><StaticImage src="../images/raum.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>   
          <Zoom><StaticImage src="../images/topf_closeup.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>  
          <Zoom><StaticImage src="../images/raum1.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>      
        </Masonry>


        </div>

      </section>




      {/*<div className="s_logo">
       <Zoom><StaticImage src="../images/seitenbacher_logo.png" alt="" placeholder="blurred" quality={100}/></Zoom>
      </div>*/}
      <h3>Redesign des Seitenbachereticketts</h3>

      <div className="etickett">
        <Zoom><StaticImage src="../images/Layout_Sticker_053.png" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/Layout_Sticker_052.png" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/Layout_Sticker_05.png" alt="" placeholder="blurred" quality={100}/></Zoom>
      </div>

      <section className="seitenbacher">
        <Zoom><StaticImage src="../images/Header_Produkt.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/pattern.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
      </section>

      <h3>Visualisierung von Verkehrsdaten</h3>
      <section className="dataportraits">
        <Zoom><StaticImage src="../images/plakat1.png" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/plakat2.png" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/plakat3.png" alt="" placeholder="blurred" quality={100}/></Zoom>

      </section>

      <h3>Visualisierung des CO2 Verbrauchs mit öffentlichen Verkehrsmitteln</h3>

      <section className="travelagency">
        <Zoom><StaticImage src="../images/travel_agency_subway.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/travel_agency_banner.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
        <Zoom><StaticImage src="../images/travel_agency.jpg" alt="" placeholder="blurred" quality={100}/></Zoom>
      </section>

      <h3>Webseiten: <br/> mitgearbeitet oder selbst umgesetzt</h3>  

     <section className="websites">
        <a target="blank" href="https://history.harting.com/"><p>History Harting @gobasil</p></a>
        <a target="blank" href="https://ibs-waltersbacher.de/"><p>IBS Waltersbacher @gobasil</p></a>
        <a target="blank" href="https://segensorte.bistum-speyer.de/"><p>Segensorte @gobasil</p></a>
        <a target="blank" href="https://dvb-fachverband.de/"><p>DVB Fachverband @gobasil</p></a>
        <a target="blank" href="https://hmc-shipbrokers.com/"><p>HMC Shipbrokers @gobasil</p></a>
        <a target="blank" href="https://sysletics.de/"><p>Sysletics @gobasil</p></a>
        <a target="blank" href="https://www.wirundhier-kongress.de/"><p>wirundhier Kongress @gobasil</p></a>
        <a target="blank" href="https://kirchenbuilder.de/"><p>Kirchenbuilder @gobasil</p></a>
        <a target="blank" href="http://www.martinajankova.com/de/"><p>Martina Jankova @gobasil</p></a>
        <a target="blank" href="https://berufmich.de/"><p>Berufmich @gobasil</p></a>
        <a target="blank" href="https://teamunser.de/"><p>Teamunser @gobasil</p></a>
        <a target="blank" href="https://godnews.de/"><p>Godnews @gobasil</p></a>
        <a target="blank" href="https://gobasil.com/"><p>Gobasil @gobasil</p></a>
        <a target="blank" href="https://campussegen.de/"><p>Campussegen @gobasil</p></a>
     </section>

     <a className="kontakt" href="/kontakt">Kontakt</a>
     

    </main>
  )
}

export default IndexPage
